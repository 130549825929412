<template>
    <div :style="{'width':'100%', 'height':xheight+'px'}">
        <div class="py-5" style="background-color: rgba(204, 21, 21, 1);">
            <v-row no-gutters class="mx-5 my-5">
                <v-col cols="8" style="display: flex; align-items: center; justify-items: center;">
                        <v-row no-gutters>
                            <h1 style="color: silver;">MEMBER {{ toCapital(member.member_type ? member.member_type : 'bronze') }}</h1>
                        </v-row>
                </v-col>
                <v-col cols="4" align="center">
                    <v-row no-gutters justify="center" align="center">
                        <v-img
                            max-height="48"
                            max-width="48"
                            :src="require('../assets/icon_membership.svg')"
                            style="color: silver;"
                        ></v-img>
                        <p class="font-weight-medium text-center mt-2 mb-0" style="font-size: small; color: white;">point reward: {{ member.rest_point ? formatThousand(member.rest_point) : 0 }}</p>
                    </v-row>
                </v-col>
                <v-col cols="12" class="mt-4">
                    <p class="font-weight-bold mb-0" style="font-size: medium; color: white">{{ member.member_name ? member.member_name : '-' }}</p>
                    <p class="font-weight-bold mb-0" style="font-size: medium; color: white">{{ member.member_number ? member.member_number : '0000 1111 2222 3333' }}</p>
                    <p class="font-weight-medium mb-0" style="font-size: small; color: white">{{ user_pat.Alamat ? user_pat.Alamat: '-' }}</p>
                </v-col>
            </v-row>
        </div>
        <div>
            <v-row justify="space-between" class="mx-5 my-5">
                <p class="font-weight-bold mb-0" style="font-size: medium;">Point History</p>
                <p class="font-weight-bold mb-0" style="font-size: medium; color: gray;">*Last 10 Transaction*</p>
            </v-row>
            <div v-if="list_point.length > 0" v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="10" :style="{'overflow-y':'auto', 'height':hcardlist()}">
                <v-card
                    class="rounded mx-5 my-4 pa-2 d-flex"
                    elevation="1"
                    v-for="(data, index) in list_point"
                    :key="index"
                >
                    <v-card-text class="px-1 py-1">
                        <v-row no-gutters justify="space-between">
                            <p class="font-weight-bold mb-0" style="font-size: small; color: black;">{{ formatDate(data.TxDate) }}</p>
                            <p class="font-weight-bold mb-0" style="font-size: small;">{{ data.Nolab }}</p>
                        </v-row>
                        <v-row no-gutters class="mt-4" align="center">
                            <v-col cols="1" align="start">
                                <v-img 
                                    :src="require('../assets/icon_cabang.svg')"
                                    width="24"
                                    height="24"
                                ></v-img>
                            </v-col>
                            <v-col cols="7">
                                <p class="font-weight-medium mb-0" style="font-size: medium;">{{ data.BranchName }}</p>
                            </v-col>
                            <v-col cols="1" align="end">
                                <v-img
                                    :src="require('../assets/icon_point.svg')"
                                    width="24"
                                    height="24"
                                ></v-img>
                            </v-col>
                            <v-col cols="3" align="end">
                                <p v-if="data.TxType === 'IN'" class="font-weight-medium mb-0 success--text text-center" style="font-size: medium;"
                                > <v-icon class="success--text">mdi-plus</v-icon> {{ formatThousand(data.Point) }}</p>
                                <p v-else class="font-weight-medium mb-0 error--text text-center" style="font-size: medium;"
                                > <v-icon class="error--text">mdi-minus</v-icon> {{ formatThousand(data.Point) }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </div>
            <div v-else :style="{'overflow-y':'auto', 'height':hcardlist()}">
                <p class="font-weight-bold mb-0 text-center" style="font-size: medium; color: gray;">Tidak ada riwayat poin</p>
            </div>

            <!-- <div :style="{'overflow-y':'auto', 'height':hcardlist()}">
                <v-card
                    class="rounded mx-5 my-4 pa-2 d-flex"
                    elevation="1"
                    v-for="(data, index) in list_point"
                    :key="index"
                >
                    <v-card-text class="px-1 py-1">
                        <v-row no-gutters justify="space-between">
                            <p class="font-weight-bold mb-0" style="font-size: small; color: black;">{{ formatDate(data.TxDate) }}</p>
                            <p class="font-weight-bold mb-0" style="font-size: small;">{{ data.Nolab }}</p>
                        </v-row>
                        <v-row no-gutters class="mt-4" align="center">
                            <v-col cols="1" align="start">
                                <v-img 
                                    :src="require('../assets/icon_cabang.svg')"
                                    width="24"
                                    height="24"
                                ></v-img>
                            </v-col>
                            <v-col cols="8">
                                <p class="font-weight-medium mb-0" style="font-size: medium;">{{ data.BranchName }}</p>
                            </v-col>
                            <v-col cols="1" align="end">
                                <v-img
                                    :src="require('../assets/icon_point.svg')"
                                    width="24"
                                    height="24"
                                ></v-img>
                            </v-col>
                            <v-col cols="2" align="end">
                                <p v-if="data.TxType === 'IN'" class="font-weight-medium mb-0 success--text" style="font-size: medium;">{{ data.Point }}</p>
                                <p v-else class="font-weight-medium mb-0 error--text" style="font-size: medium;">{{ data.Point }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <infinite-loading spinner="bubles" slot="append" @infinite="infiniteHandler" force-use-infinite-wrapper=".el-table__body-wrapper"></infinite-loading>
            </div> -->
        </div>
        <div class="pa-4 mt-2" style="background-color: white">
            <v-btn :disabled="!member.member_number" color="rgba(204,21,21,255)" large block @click="showQRcode">
                <v-icon style="color: white; margin-right: 8px;">mdi-card-account-details-outline</v-icon> 
                <span style="color: white;">SHOW MEMBER CARD</span>
            </v-btn>
        </div>
        <v-dialog persistent max-width="400px" v-model="dialog">
            <v-card class="pa-4">
                <div class="mt-2 mb-2" 
                    :style="{'background-color': 'white', 
                        'display': 'flex', 'justify-content': 'center', 'align-item':'center',
                        'flex-direction': 'column'
                    }"
                >
                    <v-img class="rounded-xl elevation-2 my-2" contain :src="cardurl" @error="handleImgError" style="border: 2px solid silver;"></v-img>
                    <v-img contain :src="qrurl" @error="handleImgError"></v-img>
                </div>
                <v-btn color="rgba(204,21,21,255)" large block @click="showQRcode">
                    <span style="color: white;">CLOSE</span>
                </v-btn>
            </v-card>
        </v-dialog>
        <v-dialog persistent max-width="400px" v-model="not_member">
            <div style="background-color: white;">
                <v-toolbar
                color="warning"
                dark
                dense
                >Peringatan</v-toolbar>
                <v-card-text>
                <div class="mt-3 pa-2 text-center">Anda belum terdaftar sebagai Member</div>
                </v-card-text>                
                <v-card-actions class="justify-end">
                    <v-btn text @click="showIsMember">Tutup</v-btn>
                </v-card-actions>
            </div>
        </v-dialog>
    </div>
</template>

<style scoped>
    .top-card {
        max-height: htopcard();
    }
</style>

<script>
import InfiniteLoading from 'vue-infinite-loading';
    export default {
        name: 'xhistorypoint',
        components : {
            InfiniteLoading
        },
        mounted() {
            let nik = this.$store.state.patient.user_patient.M_PatientIDNumber
            this.$store.dispatch("point/getMember", { nik: nik})
            this.$store.dispatch("point/getPoints", { nik: nik, page: 1, perPage: 10 })
            // this.cardurl = `https://bankpoint.jala.my.id/one-api/point/Vcard/show/${nik}`
            this.cardurl = `https://bankpoint.pramita.co.id/one-api/point/Vcard/showcard/${nik}`
            // this.cardurl = `https://bankpoint.pramita.co.id/one-api/point/Vcard/showcard/3578204105690003`
            // https://bankpoint.pramita.co.id/one-api/point/Vcard/showcardqr/3304111403960001
            // https://bankpoint.pramita.co.id/one-api/point/Vcard/showcard/3304111403960001
            this.qrurl = `https://bankpoint.pramita.co.id/one-api/point/Vcard/showqr/${nik}`
        },
        methods: {
            infiniteHandler($state) {
                let nik = this.user_pat.M_PatientIDNumber
                var prm = {
                    nik: nik,
                    page: this.page,
                    perPage: 10
                }
                // var 
                // [ ] implement infinite loading 
            },
            handleImgError() {
                this.cardurl = require('../assets/bg_merah.png')
                this.qrurl = require('../assets/bg_merah.png')
            },
            htopcard() {
                let temp = this.xheight * 0.25
                console.log(temp + 'px')
                return temp+'px'
            },
            hcardlist() {
                let temp = this.xheight * 0.6
                console.log(temp + 'px')
                return temp+'px'
            },
            hbotbtn() {
                let temp = this.xheight * 0.05
                console.log(temp + 'px')
                return temp+'px'
            },
            showQRcode() {
                this.dialog = !this.dialog
            },
            showIsMember() {
                this.not_member = !this.not_member
                this.$router.go(-1)
            },
            formatDate(date) {
                const parsed = new Date(date);
                const options = {
                    day: 'numeric',
                    month: 'long',
                    year: 'numeric'
                };
                return parsed.toLocaleDateString("id-ID", options)
            },
            toCapital(text) {
                let a = text.toUpperCase()
                return a
            },
            loadMore() {
                if (this.has_point) {
                    // let nik = this.$store.state.patient.user_patient.M_PatientIDNumber
                    let nik = this.user_pat.M_PatientIDNumber
                    this.busy = true;
                    let page = this.page + 1
                    console.log("re-add point")
                    setTimeout(() => {
                        this.$store.dispatch("point/refreshPoint", { nik: nik, page: page, perPage: 10 });
                        this.page = page;
                        this.busy = false;
                    }, 1000);
                }
            },
            formatThousand(data) {
                let temp = parseInt(data);
                const formatter = new Intl.NumberFormat('id-ID', {
                    style: 'decimal',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                });

                const formatedNumber = formatter.format(temp);
                return formatedNumber
            }
        },
        computed: {
            xheight: {
                get() {
                    return window.innerHeight
                }
            },
            list_point: {
                get() {
                    return this.$store.state.point.list_points
                }
            },
            member: {
                get() {
                    return this.$store.state.point.member
                }
            },
            has_point: {
                get() {
                    return this.$store.state.point.has_point
                }
            },
            user_pat: {
                get() {
                    return this.$store.state.patient.user_patient
                }
            },
            not_member: {
                get() {
                    return this.$store.state.point.notMember
                },
                set(val) {
                    this.$store.commit("point/update_notMember", val)
                }
            }
        },
        data() {
            return {
                windowHeight: window.innerHeight,
                windowWidth: window.innerWidth,
                dialog: false,
                page: 1,
                busy: false,
                cardurl: "",
                qrurl: ""
            }
        },
    }
</script>