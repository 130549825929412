<template>
  <v-app>
    <v-main>
      <v-container class="pa-0">
        <v-sheet max-width="460px" class="pa-0 mx-auto">
            <v-snackbar
              style="z-index:99999999999999999999999999999"
              v-model="snackWithButtons"
              top
              right
              timeout="-1"
        >
        {{ snackWithBtnText }}
        <template v-slot:action="{ attrs }">
          <v-btn
            text
            color="#00f500"
            v-bind="attrs"
            @click.stop="refreshApp"
          >
            {{ snackBtnText }}
          </v-btn>
          <v-btn
            icon
            dark
            class="ml-4"
            @click="snackWithButtons = false"
          >
            <v-icon>fa-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
            <DialogLoading/>
                  <DialogError/>
                  <DialogWarning/>
              
          <router-view/>
        </v-sheet>
      </v-container>
       
    </v-main>
  </v-app>
</template>

<script>

window.interval_status = ''
window.interval_login = ''
//window.hostx = 'https://sasmobile.aplikasi.web.id'
//window.apix = 'https://sasmobile.aplikasi.web.id'

window.hostx = 'https://mobile.pramita.co.id'
window.apix = 'https://mobile.pramita.co.id'

// window.hostx = 'https://devregonline.pramita.co.id'
// window.apix = 'https://devregonline.pramita.co.id'

import store from '@/store';
import DialogLoading from '@/components/DialogLoading';
import DialogError from '@/components/DialogError';
import DialogWarning from '@/components/DialogWarning';
import DialogInfo from '@/components/DialogInfo';

export default {
  name: 'App',
  created() {
    document.addEventListener('swUpdated', this.showRefreshUI, { once: true });
    // Refresh all open app tabs when a new service worker is installed.
    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        if (this.refreshing) return;
        this.refreshing = true;
        window.location.reload();
      });
    }
  },
  components: {
      DialogLoading,
      DialogError,
      DialogWarning,
      DialogInfo
    },
  data: () => ({
    windowWidth: window.innerWidth,
    refreshing: false,
      registration: null,
      snackBtnText: '',
      snackWithBtnText: '',
      snackWithButtons: false,
  }),
    methods: {
    showRefreshUI(e) {
      this.registration = e.detail;
      this.snackBtnText = 'Refresh';
      this.snackWithBtnText = 'New version available!';
      this.snackWithButtons = true;
    },
    refreshApp() {
      this.snackWithButtons = false;
      // Protect against missing registration.waiting.
      if (!this.registration || !this.registration.waiting) { return; }
      this.registration.waiting.postMessage('skipWaiting');
    },
  }
};
</script>
