import * as api from "@/api/api.js"

export default {
    namespaced: true,
    state: {
        list_points: [],
        member: {},
        has_point: true,
        access: {},
        notMember: false
    },
    mutations: {
        update_point(state, val) {
            state.list_points = val
        },
        add_point(state, val) {
            state.list_points.push(...val)
        },
        update_member(state, val) {
            state.member = val
        },
        update_has_point(state, val) {
            state.has_point = val
        },
        update_access(state, val) {
            state.access = val
        },
        update_notMember(state, val) {
            state.notMember = val
        }
    },
    actions: {
        async getMember(context, prms) {
            try {
                let token = JSON.parse(localStorage.getItem('point_access'))
                let fn_url = `member_point/${prms.nik}`
                let params = {}
                let headers = {
                    headers: {
                        'authorization': token.token
                    }
                }
                
                let resp = await api.apiPoints(fn_url, params, headers)
                if (resp.status != "OK") {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 3, {root:true})
                    context.commit("beranda/update_api_error_message", resp.message, {root:true})
                    console.log("not ok error get member")
                } else {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 2, {root:true})
                    context.commit("beranda/update_api_error_message", '', {root:true})

                    context.commit("update_member", resp.data)
                    if (!resp.data.member_type) {
                        context.commit("update_notMember", true)
                    }
                }   
            } catch (error) {
                context.commit("beranda/update_dialog_loading", false, {root:true})
                context.commit("beranda/update_api_status", 3, {root:true})
                context.commit("beranda/update_api_error_message", 'Error get member', {root:true})
                console.log("catch error get member")
            }
        },
        async getPoints(context, prms) {
            try {
                let token = JSON.parse(localStorage.getItem('point_access'))
                let fn_url = `point/${prms.nik}/${prms.page}/${prms.perPage}`
                let params = {}
                let headers = {
                    headers: {
                        'authorization': token.token
                    }
                }
                
                let resp = await api.apiPoints(fn_url, params, headers)
                if (resp.status != "OK") {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 3, {root:true})
                    context.commit("beranda/update_api_error_message", resp.message, {root:true})
                    console.log("not ok error get data point")
                } else {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 2, {root:true})
                    context.commit("beranda/update_api_error_message", '', {root:true})
    
                    if (resp.data.length > 0) {
                        context.commit("update_point", resp.data)
                        context.commit("update_has_point", true)                        
                    } else {
                        context.commit("update_point", resp.data)
                        context.commit("update_has_point", false)
                    }

                }
            } catch (error) {
                context.commit("beranda/update_dialog_loading", false, {root:true})
                context.commit("beranda/update_api_status", 3, {root:true})
                context.commit("beranda/update_api_error_message", 'Error get points', {root:true})
                console.log("catch error get data point")
            }
        },
        async refreshPoint(context, prms) {
            try {
                let token = JSON.parse(localStorage.getItem('point_access'))
                let fn_url = `point/${prms.nik}/${prms.page}/${prms.perPage}`
                let params = {}
                let headers = {
                    headers: {
                        'authorization': token.token
                    }
                }
                
                let resp = await api.apiPoints(fn_url, params, headers)
                if (resp.status != "OK") {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 3, {root:true})
                    context.commit("beranda/update_api_error_message", resp.message, {root:true})
                    console.log("not ok error data point reload")
                } else {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 2, {root:true})
                    context.commit("beranda/update_api_error_message", '', {root:true})
    
                    if (resp.data.length > 0) {
                        context.commit("add_point", resp.data)
                        context.commit("update_has_point", true)
                    } else {
                        context.commit("update_has_point", false)
                    }
                    
                }
            } catch (error) {
                context.commit("beranda/update_dialog_loading", false, {root:true})
                context.commit("beranda/update_api_status", 3, {root:true})
                context.commit("beranda/update_api_error_message", 'Error get relaod point', {root:true})
                console.log("catch error data point reload")
            }
        },
        async getAccessToken(context) {
            try {
                let fn_url = `get_token`
                let params = {
                    client: "Mobile",
                    secret: "15a9eee65bfec8ee27b2328dfd7811db"
                }
                let headers = {}

                let resp = await api.apiPoints(fn_url, params, headers)
                if (resp.status != 'OK') {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 3, {root:true})
                    context.commit("beranda/update_api_error_message", resp.message, {root:true})
                    console.log("no ok error get access token")
                } else {
                    context.commit("beranda/update_dialog_loading", false, {root:true})
                    context.commit("beranda/update_api_status", 2, {root:true})
                    context.commit("beranda/update_api_error_message", '', {root:true})

                    let result = {
                        token: resp.token,
                        expired: resp.expired
                    }

                    context.commit("update_access", result)
                    localStorage.setItem('point_access', JSON.stringify(result))
                    console.log("point_access saved")
                }
            } catch (error) {
                context.commit("beranda/update_dialog_loading", false, {root:true})
                context.commit("beranda/update_api_status", 3, {root:true})
                context.commit("beranda/update_api_error_message", 'Error Connection', {root:true})
                console.log("catch error get access token")
            }
        },
        async checkExpired(context) {
            try {
                if (!localStorage.getItem('point_access')) {
                    console.log("token point -> get token")
                    context.dispatch("getAccessToken")
                } else {
                    let point_access = JSON.parse(localStorage.getItem('point_access'))
                    let currentDate = new Date()
                    let expireDate = new Date(point_access.expired)

                    let fn_url = `refresh_token`
                    let params = {}
                    let headers = {
                        headers: {
                            'authorization': point_access.token
                        }
                    }
                    
                    if (currentDate > expireDate) {
                        console.log("token expired")
                        context.dispatch("getAccessToken")
                    } 
                    // else {
                    //     console.log("token ok")
                    //     let resp = await api.apiPoints(fn_url, params, headers)
                    //     if (resp.status != "OK") {
                    //         context.commit("beranda/update_dialog_loading", false, {root:true})
                    //         context.commit("beranda/update_api_status", 3, {root:true})
                    //         context.commit("beranda/update_api_error_message", resp.message, {root:true})
                    //         console.log("not ok error token expired")
                    //     } else {
                    //         context.commit("beranda/update_dialog_loading", false, {root:true})
                    //         context.commit("beranda/update_api_status", 2, {root:true})
                    //         context.commit("beranda/update_api_error_message", '', {root:true})
                            
                    //         let result = {
                    //             token: resp.token,
                    //             expired: resp.expired
                    //         }
        
                    //         context.commit("update_access", result)
                    //         localStorage.setItem('point_access', JSON.stringify(result))
                    //         console.log("point_access refreshed")
                    //     }
                    // }
                }

            } catch (error) {
                context.commit("beranda/update_dialog_loading", false, {root:true})
                context.commit("beranda/update_api_status", 3, {root:true})
                context.commit("beranda/update_api_error_message", 'Error Connection', {root:true})
                console.log("catch error token expired")
            }
        }
    }
}