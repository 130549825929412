// 1 => LOADING
// 2 => DONE
// 3 => ERROR
import axios from 'axios'
import moment from 'moment';
import router from '@/router'
import * as api from "@/api/api.js"
export default {
    namespaced: true,
    state: {
        daftar_hp:'',
        otp:'',
        login_hp:'',
        login_password:'',
        api_error_message:'',
        api_status:'',
        dialog_loading:false,
        testing:'',
        show_daftar_otp:false,
        show_daftar:false,
        show_login:true,
        dialog_confirmation:false,
        otp_counting:-1,
        show_resend_otp:false,
        insert_password_hp:'',
        insert_password_password:'',
        insert_password_password_reenter:'',
        show_insert_password:false,
        status_checking_hp:false,
        color_checking_hp:'warning',
        msg_checking_hp:'',
        result_checking_hp:'',
        error_login:'',
        act:'',
        alert_login:{statue:false,type:'',msg:''},
        show_methode:false,
        otp_forget_password: '',
        show_forgetpassword: false

    },
    mutations: {
        update_show_forgetpassword(state, val) {
            state.show_forgetpassword = val
        },
        update_otp_forget_password(state, val) {
            state.otp_forget_password = val
        },
        update_show_methode(state, val) {
            state.show_methode = val
        },
        update_alert_login(state, val) {
            state.alert_login = val
        },
        update_act(state, val) {
            state.act = val
        },
        update_error_login(state, val) {
            state.error_login = val
        },
        update_result_checking_hp(state, val) {
            state.result_checking_hp = val
        },
        update_msg_checking_hp(state, val) {
            state.msg_checking_hp = val
        },
        update_color_checking_hp(state, val) {
            state.color_checking_hp = val
        },
        update_status_checking_hp(state, val) {
            state.status_checking_hp = val
        },
        update_show_insert_password(state, val) {
            state.show_insert_password = val
        },
        update_insert_password_password_reenter(state, val) {
            state.insert_password_password_reenter = val
        },
        update_insert_password_password(state, val) {
            state.insert_password_password = val
        },
        update_insert_password_hp(state, val) {
            state.insert_password_hp = val
        },
        update_show_resend_otp(state, val) {
            state.show_resend_otp = val
        },
        update_otp_counting(state, val) {
            state.otp_counting = val
        },
        update_daftar_hp(state, val) {
            state.daftar_hp = val
        },
        update_dialog_confirmation(state, val) {
            state.dialog_confirmation = val
        },
        update_show_login(state, val) {
            state.show_login = val
        },
        update_show_daftar(state, val) {
            state.show_daftar = val
        },
        update_show_daftar_otp(state, val) {
            state.show_daftar_otp = val
        },
        update_otp(state, val) {
            state.otp = val
        },
        update_login_hp(state, val) {
            state.login_hp = val
        },
        update_login_password(state, val) {
            state.login_password = val
        },
        update_lng(state, val) {
            state.lng = val
        },
        update_lat(state, val) {
            state.lat = val
        },
        update_window_width(state, val) {
            state.window_width = val
        }
    },
    actions: {
        async getOTP(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/getOTP'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  
                  context.commit("update_dialog_confirmation",false)
                  context.commit("update_show_daftar_otp",true)
                  context.commit("update_show_daftar",false)
                  var now  = moment(new Date(),"YYYY-MM-DD HH:mm:ss")
                  var then = moment(resp.data.time,"YYYY-MM-DD HH:mm:ss")
                  var incomeTicker = 120;
                  context.commit("update_show_resend_otp",false)
                  if (window.interval_login) clearInterval(window.interval_login)
                  window.interval_login = window.setInterval(function(){
                    if (incomeTicker > 0)
                        incomeTicker--;
                        context.commit("update_otp_counting",incomeTicker)
                        if (incomeTicker == 0){
                            clearInterval(window.interval_login)
                            context.commit("update_show_resend_otp",true)
                        }
                    }, 1000);   
                  //var rest_count = now.diff(then, 'seconds')
                  if ('OTPCredential' in window) { 

                    const ac = new AbortController();
                    navigator.credentials.get({
                        otp: { transport:['sms'] },
                        signal: ac.signal
                    }).then(otp => {
                        console.log(otp)
                        let value = otp.code
                        let hp = context.state.act === 'login'?context.state.login_hp:context.state.daftar_hp
                        if(value.length == 4){
                            context.commit("beranda/update_dialog_loading",true,{root:true})
                            context.dispatch("checkOTP", {otp:value,hp:hp})
                        }
                    }).catch(err => {
                        console.log(err)
                    });
                    
                    
                  } else {
                    console.log('WebOTP not supported!.')
                  }

                 
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getOTPForget(context, prm) {
            context.commit("beranda/update_api_status", 1, {root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                let fn_url = 'login/getOTPForget'
                let resp = await api.getdata(prm,fn_url)
                if (resp.status != "OK") {
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",3,{root:true})
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                } else {
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",2,{root:true})
                    context.commit("beranda/update_api_error_message",'',{root:true})
                    
                    context.commit("update_otp_forget_password",resp.data.otp)
                }
            } catch (e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async checkOTP(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/checkOTP'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)show_daftar_otp
                  if(context.state.act !== 'login'){
                    context.commit("update_show_insert_password",true)
                    context.commit("update_show_daftar_otp",false)
                    context.commit("update_insert_password_password_reenter",'')  
                    context.commit("update_insert_password_password",'') 
                    context.commit("update_insert_password_hp",resp.data[0].X_OTPHp)   
                    context.commit("order/update_hp",resp.data[0].X_OTPHp,{root:true})   
                  }
                  else{
                      var xprm = {hp:resp.data[0].X_OTPHp,password:context.state.login_password}
                      context.dispatch('login',xprm)
                  }
                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async getInitForm(context) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
                var prm = {}
               let fn_url = 'test/getInitForm'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  
                  context.commit("order/update_provinces",resp.provinces,{root:true})
                  context.commit("order/update_selected_province",{},{root:true})
                  context.commit("order/update_ids",resp.ids,{root:true})
                  context.commit("order/update_selected_id",{},{root:true})
                  context.commit("order/update_selected_title",{},{root:true})
                  context.commit("order/update_titles",resp.titles,{root:true})
                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async checkHP(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/checkHP'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_status_checking_hp",false)
                  context.commit("update_result_checking_hp",resp.data.status) 
                  context.commit("update_msg_checking_hp",resp.data.msg) 
                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",resp.message,{root:true})
            }
        },
        async newUser(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/newUserX'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)show_daftar_otp
                  localStorage.setItem('user', JSON.stringify(resp.data))
                  //axios.defaults.headers.common['Authorization'] = resp.data.token
                  //console.log(oneState)
                  context.commit("update_daftar_hp",'')
 
                  var oneState = JSON.parse(localStorage.getItem("oneState")) 
                  //console.log(oneState)
                    if(oneState && context.rootState.beranda.last_menu !== '')
                        router.push({ name: context.rootState.beranda.last_menu })
                    else
                        router.push({name:'xberanda'})
                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async forgetPass(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            try {
               let fn_url = 'login/forgetPass'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",3,{root:true})
                  context.commit("beranda/update_api_error_message",resp.message,{root:true})
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  context.commit("update_alert_login",{status:true,msg:resp.message,type:resp.type})
                  setInterval(function(){ context.commit("update_alert_login",{status:false,msg:'',type:''}) }, 10000)
                  context.commit("update_show_login",true)
                  context.commit("update_show_insert_password",false)
                  context.commit("update_show_daftar",false)
                  context.commit("update_show_daftar_otp",false)
                  context.commit("update_show_forgetpassword", false)
                  context.commit("update_insert_password_hp", '')
                  context.commit("update_insert_password_password", '')
                  context.commit("update_insert_password_password_reenter", '')
                  context.commit("update_otp_forget_password", '')
                  

                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async login(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            context.commit("update_error_login",false)
            
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/login'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",3,{root:true})
                    context.state.show_login = true
                    context.state.show_insert_password = false
                    context.state.show_methode = false
                    context.state.show_daftar = false
                    context.state.show_daftar_otp = false
                    if(resp.status != "INVALID_USER"){
                        context.commit("beranda/update_api_error_message",resp.message,{root:true})
                    }
                    else{
                        context.commit("update_error_login",true)
                    }
                        
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)show_daftar_otp
                  localStorage.setItem('user', JSON.stringify(resp.data))
                //   localStorage.setItem('fcm', JSON.stringify({token:resp.data.token,xdate:moment(new Date())}))
                  localStorage.setItem('fcm', JSON.stringify({token:moment(new Date()), xdate:moment(new Date())}))
                  var oneState = JSON.parse(localStorage.getItem("oneState")) 
                  //console.log(oneState)
                    if(oneState && context.rootState.beranda.last_menu !== '')
                        router.push({ name: context.rootState.beranda.last_menu })
                    else
                        router.push({name:'xberanda'})

                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async logout(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            context.commit("update_error_login",false)
            
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'login/xlogout'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",3,{root:true})
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                        
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)show_daftar_otp
                  var user = JSON.parse(localStorage.getItem("user"))
                  user.token = ''
                  localStorage.setItem('user', JSON.stringify(user))
                  localStorage.removeItem('oneState')
                  localStorage.removeItem('point_access')
                  delete axios.defaults.headers.common['Authorization']
                  //location.reload();
                  router.push({name:'xlogin'})
                  
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        },
        async delete_account(context,prm) {
            context.commit("beranda/update_api_status",1,{root:true})
            context.commit("update_error_login",false)
            
            try {
                //console.log('modules')
                //console.log(prm)
               let fn_url = 'account/delete_account'
               let resp= await api.getdata(prm,fn_url)
               if (resp.status != "OK") {
                    context.commit("beranda/update_dialog_loading",false,{root:true})
                    context.commit("beranda/update_api_status",3,{root:true})
                    context.commit("beranda/update_api_error_message",resp.message,{root:true})
                        
               } else {
                  context.commit("beranda/update_dialog_loading",false,{root:true})
                  context.commit("beranda/update_api_status",2,{root:true})
                  context.commit("beranda/update_api_error_message",'',{root:true})
                  //console.log(resp.data)show_daftar_otp
                  context.commit("order/update_delete_akun_otp",false,{root:true})
                  context.commit("order/update_success_delete_account",true,{root:true})
                }
            } catch(e) {
                context.commit("beranda/update_dialog_loading",false,{root:true})
                context.commit("beranda/update_api_status",3,{root:true})
                context.commit("beranda/update_api_error_message",'',{root:true})
            }
        }

    }
}